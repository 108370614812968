import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Loader from '../general/Loader';
import './carousel.css';
import { Link } from 'react-router-dom';
import IMG01 from '../../media/img/CAROUSEL/IMG01_1.webp';
import IMG02 from '../../media/img/CAROUSEL/IMG02_2.webp';
import IMG03 from '../../media/img/CAROUSEL/IMGWEB01.webp';
import IMG04 from '../../media/img/CAROUSEL/IMG04_4.webp';
import IMG05 from '../../media/img/CAROUSEL/IMG05_5.webp';
import IMG06 from '../../media/img/CAROUSEL/IMG06_6.webp';

function CarouselInicio() {
  const images = [
    {
      url: IMG01,
      button: false,
      to: '/contacto',
      textbtn: 'Contáctanos',
      className: 'text-carousel tr rl bgd-pink'
    },
    {
      url: IMG02,
      button: false,
      textbtn: 'Contactanos 2',
      className: 'text-carousel tr rl'
    },
    {
      url: IMG03,
      button: false,
      textbtn: 'Saber más...',
      className: 'text-carousel tl rr bgd-pink'
    },
    {
      url: IMG04,
      button: false,
      to: '/estudios',
      textbtn: 'Saber más...',
      className: 'text-carousel tr rl'
    },
    {
      url: IMG05,
      button: false,
      to: '/estudios',
      textbtn: 'Saber más...',
      className: 'text-carousel tr rl'
    },
    {
      url: IMG06,
      button: false,
      to: '/estudios',
      textbtn: 'Saber más...',
      className: 'text-carousel tr rl'
    }
  ];
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fakeLoading = setTimeout(() => {
      setLoading(false); // Simulación de carga completada después de 2 segundos
    }, 2000);

    return () => {
      clearTimeout(fakeLoading); // Limpia el temporizador al desmontar el componente
    };
  }, []);

  return (
    <>
      <div className='container-carousel'>
        <Carousel className="responsive-carousel">
          {images.map((image, index) => (
            <Carousel.Item key={index} interval={3000} indicators={false}>
              <div className={image.className}>
                {image.button && <Link to={image.to}><button>{image.textbtn}</button></Link>}
              </div>
              <img src={image.url} alt={`Slide ${index + 1}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      {loading ? <Loader className="loader-lg" /> : null}
    </>

  );
}

export default CarouselInicio;
