import React from 'react';
import { useInView, animated, useSpring } from '@react-spring/web';

const Animated = ({ children, useScale = false, mh = false }) => {
    const [ref, springs] = useInView(
        () => ({
            from: {
                opacity: 0,
                y: 100,
            },
            to: {
                opacity: 1,
                y: 0,
            },
            config: {
                duration: 450, // Duración en milisegundos (1 segundo)
            }
        }),
    )
    const [ref2, inView] = useInView({
        rootMargin: '-15% 0px -10% 0px',
    });
    const styles = useSpring({
        scale: inView ? 1 : 0,
        config: {
            duration: 450,
            tension: 300,
        },
    })

    return (
        <animated.div ref={useScale ? ref2 : ref} style={{ ...useScale ? styles : springs, height: mh ? "100%" : "auto" }}>
            {children}
        </animated.div>
    );
}

export default Animated;
