import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/paginas/contacto.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Animated from '../animated/animated';
import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';

const Contacto = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            const isMobileNow = window.innerWidth <= 768; // Puedes ajustar este valor según tus necesidades
            setIsMobile(isMobileNow);
        };

        // Llama a handleResize al cargar la página
        handleResize();

        // Agrega el evento de escucha para cambios en el tamaño de la ventana
        window.addEventListener('resize', handleResize);

        // Limpia el evento de escucha al desmontar el componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Row className='rb'>
            <Animated>
                <Col md="12">
                    <Card.Title>CONTACTANOS</Card.Title>
                </Col>
            </Animated>
            <Col md="12" lg="12" xl="8">
                <Animated>
                    <Card className="border-0 rounded-0 bg-gray">
                        <Card.Body style={{ alignSelf: 'center' }}>
                            {isMobile ? (
                                <iframe
                                    title='ubicacion Google maps'
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.2935413863925!2d-96.91782542532387!3d19.529007237596073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85db33472edd6119%3A0x2b4fda70227d553b!2sOrtodoncia%20Alma%20Velasco.!5e0!3m2!1ses-419!2smx!4v1703738764099!5m2!1ses-419!2smx"
                                    width="310"
                                    height="400"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>

                            ) : (
                                <iframe
                                    title='Google maps'
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.293541386406!2d-96.9178254252413!3d19.52900723759588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85db33472edd6119%3A0x2b4fda70227d553b!2sOrtodoncia%20Alma%20Velasco.%20Dra.%20Alma%20Ang%C3%A9lica%20Velasco%20Bandala!5e0!3m2!1ses-419!2smx!4v1695268150972!5m2!1ses-419!2smx"
                                    width="600"
                                    height="450"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            )}
                        </Card.Body>
                    </Card>
                </Animated>
            </Col>
            <Col md="8" xs="10" lg="4" style={{ alignSelf: 'center' }} className='textme'>
                <Animated>
                    <div className='contact bg-pink rounded-1'>
                        <p >
                            <FaWhatsapp size={20} /> <a href="https://wa.me/522281250185?text=Hola,%20quisiera%20más%20información%20por%20favor." target="_blank" rel="noopener noreferrer">WhatsApp: <br></br> 228 125 0185</a>
                        </p>
                        <p >
                            <FaFacebook size={20} /> <a href="https://m.facebook.com/profile.php?id=100057854635180" target="_blank" rel="noopener noreferrer">Facebook: <br></br> Ortodocia Alma Velasco</a>
                        </p>
                        <p >
                            <FaInstagram size={20} /> <a href="https://www.instagram.com/ortodoncia.almavelasco/" target="_blank" rel="noopener noreferrer">Instagram: ortodoncia.almavelasco</a>
                        </p>
                        <p >
                            <FaTiktok size={20} /> <a href="https://www.tiktok.com/@ortodoncia.almavelasco" target="_blank" rel="noopener noreferrer">Tiktok: <br></br>ortodoncia.almavelasco</a>
                        </p>
                        <p >
                            <FaLinkedin size={20} /> <a href="https://www.linkedin.com/in/alma-ang%C3%A9lica-velasco-bandala-36a220133/" target="_blank" rel="noopener noreferrer">LinkedIn: <br></br>Alma Angélica Velasco Bandala</a>
                        </p>
                    </div>
                </Animated>
            </Col>
        </Row>
    );
}

export default Contacto;
