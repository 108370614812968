import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import '../../styles/paginas/testimonios.css';
import Animated from '../animated/animated';

function Testimonios() {

  return (
    <Container className='container-testimonios'>
      <Row >
        <Animated>
          <Col md={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 0 }} style={{ textAlign: 'center', color: "" }} className='bg-gray mt-1'>
            <Card.Subtitle className="mb-2 text-muted">
              Descubre las experiencias compartidas por nuestros <strong className='pink'>pacientes</strong>.
              <br /> Xalapa, Veracruz.
            </Card.Subtitle>
          </Col>
        </Animated>
        <Col xs={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} className='mt-2'>
          <Animated>
            <iframe
              width="100%"
              height="530"
              src="https://www.youtube.com/embed/LadlCCI7aEc?si=Dm6lJEZTtWH0QgZN&autoplay=1&amp;controls=1&autohide=2"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen>
            </iframe>
          </Animated>
        </Col>

      </Row>
    </Container>
  );
}

export default Testimonios;
