import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaRegStar } from "react-icons/fa6";
import { FaStar } from "react-icons/fa6";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Animated from '../animated/animated';

function CarouselRewards() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const cardsData = [
        {
            title: "Rosa Gomez",
            url: "https://maps.app.goo.gl/7FZuFy2TjuvLN8L79", 
            stars: 5,
            description: "¡Excelente atención que te da la Dra.! La empatía que tiene, el profesionalismo y sobre todo la vocación. Yo siempre dije que con ella quiero y voy a llevar mi tratamiento de ortodoncia. 10/10 *****",
        },
        {
            title: "Esmeralda Hernandez Murrieta",
            url: "https://maps.app.goo.gl/Tma97RiTWoZW8xLH6", 
            stars: 5,
            description: "Muy linda la Dra en todos los aspectos, se ve que es una persona muy profesional y dedicada en su área, también su asistente es muy linda y amable; a mi hermano y mi nos gustó su servicio. 💗",
        },
        {
            title: "Daniela Juárez",
            url: "https://maps.app.goo.gl/VJGuhHKSC4hyh62NA", 
            stars: 5,
            description: "Exelente trato, con todos los cuidados de higiene,  la Dra. muy profecional y puntual",
        },
        {
            title: "Jose Luis Aguilar Nolasco",
            url: "https://maps.app.goo.gl/H4tPd5o1pK2SzcAA9", 
            stars: 5,
            description: "Una persona muy profesional la Dr. Alma. Desde que pedí información, durante todo mi tratamiento hasta el final del mismo." +
                "Calidad humana y experta en lo que hace. 100% recomendada. 🙏…",
        },
        {
            title: "JA CR",
            url: "https://maps.app.goo.gl/CYPQunKBP5MB2db58", 
            stars: 5,
            description: "Excelente servicio por parte de todo el personal; la ortodoncista y su asistente Mili. La Dra muy amable explica todo a detalle y resuelve las dudas que puedan surgir. 100% recomendada ",
        },
    ];

    return (
        <>
            <Col md={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 0 }} className='mb-4'>
                <br />
                <Animated>
                    <Slider {...settings} className='rewards'>
                        {cardsData.map((card, index) => (
                            <Row>
                                <Col md={{ span: 10, offset: 1 }} xlg={{ span: 10, offset: 1 }} className='mb-2'>
                                    <a href={card.url} target="_blank">
                                        <Card className="border-0 bg-pink">
                                            <Card.Body >
                                                <Card.Title>
                                                    <strong className='txt-xs'>{card.title}</strong>
                                                    <br />
                                                    {Array.from({ length: card.stars }, (_, i) => (
                                                        <FaStar key={i} color="white" />
                                                    ))}
                                                    {Array.from({ length: 5 - card.stars }, (_, i) => (
                                                        <FaRegStar key={i} color="white" />
                                                    ))}
                                                </Card.Title>
                                                <Card.Text className='font-weight-light'>
                                                    {card.description}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Col>
                            </Row>
                        ))}
                    </Slider>
                </Animated>
            </Col>
        </>
    );
}

export default CarouselRewards;
