import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InstagramPost from '../instagram/instagramPost';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Loader from '../general/Loader';
import Animated from '../animated/animated';

function Tips() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fakeLoading = setTimeout(() => {
            setLoading(false); // Simulación de carga completada después de 2 segundos
        }, 4500);

        const script = document.createElement('script');
        script.async = true;
        script.src = '//www.instagram.com/embed.js';
        document.body.appendChild(script);

        return () => {
            clearTimeout(fakeLoading);
            document.body.removeChild(script);
        };
    }, []);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <Row>
                <Animated>
                    <Col md={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 0 }} style={{ textAlign: 'center', color: "" }} className='bg-white'>
                        <Card.Subtitle className="mb-2 text-muted">
                            En este apartado podrás encontrar algunos tips que te servirán de apoyo durante tu tratamiento de ortodoncia, así como respuesta a algunas <strong className='pink'>dudas comunes</strong> que tienen nuestros pacientes.
                            <br /> Te invito a seguir nuestras <strong className='pink'>redes sociales</strong>.
                        </Card.Subtitle>
                    </Col>
                </Animated>
                <Col md={{ span: 5, offset: 0 }} xs={{ span: 10, offset: 1 }} >
                    <br />
                    <Slider {...settings}>
                        <InstagramPost url={"https://www.instagram.com/reel/Ce1JGFEFwUj/?utm_source=ig_embed&utm_campaign=loading"} texto={"Texto explicativo del reel"} />
                        <InstagramPost url={"https://www.instagram.com/reel/Cf5L4d9lo4Y/?utm_source=ig_embed&utm_campaign=loading"} texto={"Texto explicativo del reel"} />
                        <InstagramPost url={"https://www.instagram.com/reel/CgzpigEl4zZ/?utm_source=ig_embed&utm_campaign=loading"} texto={"Texto explicativo del reel"} />
                        <InstagramPost url={"https://www.instagram.com/reel/CgLKJKDFCSA/?utm_source=ig_embed&utm_campaign=loading"} texto={"Texto explicativo del reel"} />
                        <InstagramPost url={"https://www.instagram.com/reel/ChutV6Yp4SU/?utm_source=ig_embed&utm_campaign=loading"} texto={"Texto explicativo del reel"} />
                    </Slider>
                </Col>
                <Col md={{ span: 5, offset: 2 }} xs={{ span: 10, offset: 1 }}>
                    <br />
                    <Slider {...settings}>
                        <InstagramPost url={"https://www.instagram.com/reel/Cpd9dSzvdPc/?utm_source=ig_embed&utm_campaign=loading"} texto={"El principal objetivo de la cera dental es proteger las áreas sensibles de la boca, como lo son las encías, los labios y el interior de las mejillas. Su aspecto por lo general es transparente, por lo que no se ve después de aplicarla. (ejemplo)"} />
                        <InstagramPost url={"https://www.instagram.com/reel/CfE5v8RlbAu/?utm_source=ig_embed&utm_campaign=loading"} texto={"Texto explicativo del reel"} />
                        <InstagramPost url={"https://www.instagram.com/reel/CeWRQN-F0-M/?utm_source=ig_embed&utm_campaign=loading"} texto={"Texto explicativo del reel"} />
                        <InstagramPost url={"https://www.instagram.com/reel/CwF3LR0thkV/?utm_source=ig_embed&utm_campaign=loading"} texto={"Texto explicativo del reel"} />
                    </Slider>
                </Col>
            </Row>
            {loading ? <Loader className="loader-lg" /> : null}</>
    );
}

export default Tips;
