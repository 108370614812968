import '../../styles/header/Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../media/Logocorto.png';

function Header() {
  const location = useLocation();


  const isActive = (pathname) => {
    return location.pathname === pathname ? 'active' : '';
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs lg="10">
          <header>
            <nav className='menu-horizontal'>
              <ul>
                <li><Link className={isActive('/')} to="/">INICIO</Link></li>
                <li><Link className={isActive('/acerca-de')} to="/acerca-de">ACERCA DE</Link></li>
                <li><Link className={isActive('/ortodoncia')} to="/ortodoncia">ORTODONCIA</Link></li>
                <li><Link className={isActive('/estudios')} to="/estudios">ESTUDIOS</Link></li>
                <Link to="/" style={{paddingTop:"0"}}>
                  <img src={Logo} className="App-logo" alt="logo" />
                </Link>

                <li><Link className={`menu-right ${isActive('/avance-de-casos')}`} to="/avance-de-casos">CASOS</Link></li>
                <li><Link className={`menu-right ${isActive('/testimonios')}`} to="/testimonios">TESTIMONIOS</Link></li>
                <li><Link className={`menu-right ${isActive('/tips')}`} to="/tips">TIPS</Link></li>
                <li><Link className={`menu-right ${isActive('/contacto')}`} to="/contacto">CONTACTO</Link></li>
              </ul>
              <hr style={{ border: "none", borderTop: "1px solid #ccc", margin: "0.5% 0% 0% 0%", width: "100%" }} />
            </nav>
          </header>
        </Col>
      </Row>
    </Container>
  );
}

export default Header;
