import React from 'react';
import '../../styles/general/Loader.css';

const Loader = ({ className }) => { // Destructuramos className de las props
  return (
    <div className={`loader ${className}`}>
      <div className="spinner"></div>
    </div>
  );
}

export default Loader;
