import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/paginas/avances.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { OverlayTrigger, Button, Popover } from 'react-bootstrap';
import GridImages from '../gridImages/GridImages';
import { FaCircleInfo } from "react-icons/fa6";
import { IconContext } from "react-icons";

function Avances() {
    const [activeCategory, setActiveCategory] = useState('todos');

    const handleCategoryChange = (category) => {
        setActiveCategory(category);
    };

    const isActive = (category) => {
        return activeCategory.toLowerCase() === category.toLowerCase() ? 'active' : '';
    };
    const popoverLeft = (
        <Popover id="popover-positioned-left" title="Popover left">
          <div className='text-muted m-2'>Para acceder a más información, haz clic en la imagen correspondiente.</div>
        </Popover>
      );

    return (
        <Row>
            <Col md={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 1 }} style={{ textAlign: 'center', color: "" }} className='bg-white mb-4'>
                <Card.Subtitle className="mb-1 text-muted">
                    En esta sección, te presentamos una recopilación detallada de <strong className='pink'>algunos de los casos</strong> en los que hemos dedicado nuestro esfuerzo y experiencia. &nbsp;
                    <IconContext.Provider value={{ color: '#cc9999', size: '20px' }} >
                        <OverlayTrigger trigger="click" placement="bottom" overlay={popoverLeft}>
                            <Button style={{border:"none", background: "none", padding: "0"}}><FaCircleInfo /></Button>
                        </OverlayTrigger>
                        
                    </IconContext.Provider>
                </Card.Subtitle>
            </Col>
            <Col md="12" className='menu-casos'>
                <header>
                    <nav>
                        <ul>
                            <li className={isActive('todos')} onClick={() => handleCategoryChange('todos')}>Todos</li>
                            <li className={isActive('avance')} onClick={() => handleCategoryChange('avance')}>Avances</li>
                            <li className={isActive('finalizado')} onClick={() => handleCategoryChange('finalizado')}>Finalizados</li>
                        </ul>
                        <hr style={{ border: "none", borderTop: "1px solid #ccc", margin: "1.5% 0% 0% 0%", width: "100%" }} />
                    </nav>
                </header>
            </Col>
            <Col md="12" className='images-container'>
                <GridImages activeCategory={activeCategory} />
            </Col>
        </Row>
    );
}

export default Avances;
