import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import './modalcase.css';

const ModalCase = ({ selectedImage, setShow }) => {

  console.log(selectedImage);
  return (
    <>
      <Modal show={setShow} onHide={() => setShow(false)} centered scrollable className='modal-60w'>
        <Modal.Body>
          <div className='line-white'><strong>{selectedImage.title}</strong></div>
          <hr />
          <Row>
            <Col xs="12" lg={{ span: 6, offset: 3 }}>
              {selectedImage.images ? (
                <Carousel className="case-carousel">
                  {selectedImage.images.map((image, index) => (
                    <Carousel.Item key={index} interval={3000}>
                      <img src={image.src} alt={`Avance de caso ${index + 1}`} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (<img src={selectedImage.src} alt={`Avance de caso ${selectedImage.id}`} />)}
            </Col>
            <Col xs="12" lg="12">
              <br />
              <div className='line-white' style={{ textAlign: "justify", whiteSpace: "pre-line" }}>
                {selectedImage.desc}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCase;
