import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/paginas/estudios.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from '../general/Loader';
import Animated from '../animated/animated';
import PGDL7388 from '../../media/img/PGDL7388_.webp';
import PGDL0516 from '../../media/img/PGDL0516_.webp';
import PGDL0541 from '../../media/img/PGDL0541_.webp';

function Estudios() {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fakeLoading = setTimeout(() => {
            setLoading(false); // Simulación de carga completada después de 2 segundos
        }, 1000);

        return () => {
            clearTimeout(fakeLoading); // Limpia el temporizador al desmontar el componente
        };
    }, []);

    return (
        <div className='container'>
            {loading ? <Loader className="loader-lg" /> : null}
            <Row className='estudios'>
                <Col md="12">
                    <Card className="border-0 bg-gray">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <br></br>
                            <Row>
                                <Col md={{ span: 12 }}>
                                    <Animated>
                                        <Card.Title>ESTUDIOS
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">Carrera profesional</Card.Subtitle>
                                    </Animated>
                                </Col>
                                <Col md="8">
                                    <Animated>
                                        <Card.Text className='line-pink'>
                                            Egresada como Cirujano Dentista en la Universidad Veracruzana U.V. Campus Xalapa Veracruz. 2002-2007.
                                            <p style={{fontSize: "15px"}}><strong>Cédula profesional: 5875167</strong></p>
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            Egresada De La Maestría En Salud Pública en la Universidad Veracruzana U.V. / Conacyt. Xalapa Veracruz. 2013-2015.
                                            <p style={{fontSize: "15px"}}><strong>Cédula profesional: 10576993</strong></p>
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            Egresada De La Maestría En Ciencias Estomatológicas en el Área De Ortodoncia Y Ortopedia Dentofacial en la Universidad Central De Veracruz, U.C.V.  Xalapa Veracruz. 2017-2020.
                                            <p style={{fontSize: "15px"}}><strong>Cédula profesional: 12281596</strong></p>
                                        </Card.Text>
                                    </Animated>
                                </Col>
                                <Col md="4">
                                    <img src={PGDL7388} alt="Alma velasco" className='estudios-lg rounded-1' />
                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-pink">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col md={{ span: 12 }}>
                                    <Animated>
                                        <Card.Title>ESTUDIOS
                                        </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">Actualización</Card.Subtitle>
                                    </Animated>
                                </Col>
                                <Col md="8">
                                    <Animated>
                                        <Card.Text className='line-white'>
                                            <strong className='txt-xs'>ENERO – FEBRERO 2015</strong>
                                            <br />
                                            Estancia de investigación en Medellín Colombia para conocer y estudiar la Política Pública de Salud Bucal “la boca convoca”.
                                        </Card.Text>
                                        <Card.Text className='line-white'>
                                            <strong className='txt-xs'>MAYO 2015
                                            </strong>
                                            <br />
                                            Taller “Metodología de la Investigación en la Determinación Social del Proceso Salud-Enfermedad” en el marco del 3er. Congreso Nacional de Educación en Salud Pública: Determinantes y Políticas en Salud. Guadalajara, Jalisco.
                                            <br /><br />
                                            Presentación de trabajo de investigación modalidad cartel: “Política de salud bucal “la boca convoca” Análisis del proceso de formulación. Guadalajara, Jalisco.
                                        </Card.Text>
                                        <Card.Text className='line-white'>
                                            <strong className='txt-xs'>NOVIEMBRE 2017</strong>
                                            <br />
                                            XXV Congreso Internacional de la Academia Mexicana de Ortodoncia. CDMX.
                                        </Card.Text>
                                    </Animated>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-white mt">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col lg="4">
                                    <img src={PGDL0516} alt="Alma velasco" className='estudios-md rounded-1' />
                                </Col>
                                <Col lg="8">
                                    <Animated>
                                        <Card.Text className='line-pink mg'>
                                            <strong className='txt-xs mg'>ABRIL 2018</strong>
                                            <br />
                                            Congreso de ortodoncia “Temprano no, a tiempo”, Veracruz.
                                        </Card.Text>
                                        <Card.Text className='line-pink mg'>
                                            <strong className='txt-xs'>OCTUBRE 2018</strong>
                                            <br />
                                            Congreso de ortodoncia “Fundamentos para la mecánica y la estética del tratamiento ortodóncico de la sonrisa”, Puebla.
                                        </Card.Text>
                                        <Card.Text className='line-pink mg'>
                                            <strong className='txt-xs'>ABRIL  2019</strong>
                                            <br />
                                            IV Congreso Internacional de Ortodoncia Actual. CDMX.
                                        </Card.Text>
                                        <Card.Text className='line-pink mg'>
                                            <strong className='txt-xs'>SEPTIEMBRE 2019</strong>
                                            <br />
                                            Curso teórico práctico de Mini implantes (TAD´s). CDMX.
                                        </Card.Text>
                                    </Animated>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-gray">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col xs="12">
                                    <Animated>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>NOVIEMBRE 2019</strong>
                                            <br />
                                            XXVIII Congreso Internacional “Encuentro latinoamericano de Ortodoncia” Academia Mexicana de Ortodoncia. CDMX.
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>JUNIO 2020</strong>
                                            <br />
                                            DOCENTE en IUV, en el periodo escolar 2020-2 impartiendo el
                                            programa académico:
                                            Introducción a la Educación para la Salud de la Maestría en Educación para la Salud (Modalidad Virtual).
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>ABRIL 2021</strong>
                                            <br />
                                            Certificación en tratamientos con brackets de autoligado Pitts 21. CDMX.
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>JUNIO 2021</strong>
                                            <br />
                                            Curso “The Art of the dental picture” Fotografía clínica dental. Puebla.
                                        </Card.Text>
                                    </Animated>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-pink">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col md="8">
                                    <Animated>
                                        <Card.Text className='line-white'>
                                            <strong className='txt-xs'>JULIO 2021</strong>
                                            <br />
                                            Curso Ortodoncia Invisible Personalizada, a través del programa de formación en CrystAlligner B-TRESSESENTA.
                                        </Card.Text>
                                        <Card.Text className='line-white'>
                                            <strong className='txt-xs'>SEPTIEMBRE 2021</strong>
                                            <br />
                                            Curso de formadores de primeros respondientes. SESVER.
                                        </Card.Text>
                                        <Card.Text className='line-white'>
                                            <strong className='txt-xs'>OCTUBRE 2021
                                            </strong>
                                            <br />
                                            Curso para la aplicación y uso del CBCT en odontología.
                                        </Card.Text>
                                        <Card.Text className='line-white'>
                                            <strong className='txt-xs'>OCTUBRE 2021</strong>
                                            <br />
                                            Curso biomecánicas con Microtornillos en Ortodoncia. Academia Sol.
                                        </Card.Text>
                                        <Card.Text className='line-white'>
                                            <strong className='txt-xs'>NOVIEMBRE 2021</strong>
                                            <br />
                                            Curso de Tomografía 3D para el diagnóstico en Ortodoncia.
                                        </Card.Text>
                                    </Animated>
                                </Col>
                                <Col md="4">
                                    <img src={PGDL0541} alt="Alma velasco" className='estudios-lg rounded-1' />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-white mt">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col lg="8">
                                    <Animated>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>MAYO 2022</strong>
                                            <br />
                                            Curso “Conéctate con tus pacientes siempre con un propósito”. CDMX.
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>NOVIEMBRE 2022</strong>
                                            <br />
                                            Toxina Botulínica en Odontología. CDMX.

                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>DICIEMBRE 2022</strong>
                                            <br />
                                            Curso “Armonización bucofacial” en modalidad teórico-demostrativo-práctico de Toxina Botulínica y Ácido Hialurónico. Boca del Río Ver.
                                            <br /><br />
                                            Masterclass avanzado “Rinomodelación Integral”
                                        </Card.Text>
                                    </Animated>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="border-0 bg-gray">
                        <Card.Body style={{ textAlign: 'justify' }}>
                            <Row>
                                <Col lg="12">
                                    <Animated>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>FEBRERO 2023</strong>
                                            <br />
                                            -Congreso multidisciplinario: flujos digitales en odontología. Xalapa. Ver.
                                            <br /> <br />
                                            -Mesa clínica: “La planeación detrás de la ortodoncia digital en AVANTIS.
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>MARZO 2023</strong>
                                            <br />
                                            Curso virtual Mini implantes ortodónticos O SNIPER.
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>JUNIO 2023</strong>
                                            <br />
                                            Curso de entrenamiento clínico SPARK clear aligner system. CDMX.
                                        </Card.Text>
                                        <Card.Text className='line-pink'>
                                            <strong className='txt-xs'>NOVIEMBRE 2023</strong>
                                            <br />
                                            Curso método FREEDOM para Alineadores Invisibles (en proceso año 2024).
                                        </Card.Text>
                                    </Animated>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Estudios;
