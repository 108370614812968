import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/paginas/ortodoncia.css';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from '../general/Loader';
import Animated from '../animated/animated';

function Ortodoncia() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fakeLoading = setTimeout(() => {
            setLoading(false); // Simulación de carga completada después de 2 segundos
        }, 2500);

        return () => {
            clearTimeout(fakeLoading); // Limpia el temporizador al desmontar el componente
        };
    }, []);

    return (
        <>
            <Row>
                <Animated>
                    <Col md={{ span: 12 }} xs={{ span: 11 }}>
                        <Card className="border-0 bg-pink">
                            <Card.Body style={{ textAlign: 'justify' }}>
                                <Row>
                                    <Col md={{ span: 12 }} xs="12" style={{ textAlign: 'center', color: "white" }}>
                                        <Card.Title>¿Sabes que es la ortodoncia? </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted">Conoce la opinión de nuestra ortodoncista Alma Velasco</Card.Subtitle>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <br></br>
                    </Col>
                </Animated>
                <Animated>
                    <Col md={{ span: 12 }}>
                        <iframe
                            width="100%"
                            height="515"
                            src="https://www.youtube.com/embed/ZgKyV_ViSzE?si=WYrT-epbF_6aPMNw&autoplay=1&amp;controls=1&autohide=2"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen>
                        </iframe>
                    </Col>
                    
                </Animated>
            </Row>
            {loading ? <Loader className="loader-lg" /> : null}
        </>
    );
}

export default Ortodoncia;
